angular.module('enervexSalesappApp').factory('ExcludeMetaUtil', function SelectionMetaUtil(_, Util) {
	return {
		getExcludeMeta: function(vent, design) {
			var value = _.find(design.stack.excludeMetas, function(o){
				if (vent.ID == o.ID) {
					return true
				} else {
					return false
				}
			})
			if (!value) {
				value = {
					ID: vent.ID,
					GUIidentPath: vent.GUIidentPath,
					excludeSubtypes: []
				}
				design.stack.excludeMetas.push(value)
			}
			if (!value.excludeSubtypes) {
				value.excludeSubtypes = []
			}
			if (vent.ID && vent.ID != '') {
				value.ID = vent.ID
			}
			if (vent.GUIidentPath && vent.GUIidentPath != '') {
				value.GUIidentPath = vent.GUIidentPath
			}
			return value
		},
		removeExcludeMeta: function(vent, design) {
			var value = _.find(design.stack.excludeMetas, function(o){
				if (vent.ID == o.ID) {
					return true
				} else {
					return false
				}
			})

			if (value.excludeSubtypes.length > 0) {
				value.excludeSubtypes = []
			}

			return value
		},
		cloneMeta: function(meta) {
			var clone = _.clone(meta)
			delete meta.ID
			delete meta.GUIidentPath
			delete meta.GUIident
			return clone
		}
	}
})
